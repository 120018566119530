import Vue from 'vue'
import VueRouter from 'vue-router'
import orderdetail from '../components/AppFrame.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../components/AppFrame.vue'),
  },
  {  
    path: '/orderdetail/:orderCode',
    name: 'orderdetail',
    component : orderdetail
   // component: () => import('../components/AppFrame.vue')
  },
  {  
    path: '/receiptdetail/:receiptcode',
    name: 'receiptdetail',
    component: () => import('../components/AppFrame.vue')
  },
  {
    path: '/application',
    name: 'application',
    component: () => import('../components/AppFrame.vue')
  },
  {
    path: '/microsoftcallback',
    name: 'microsoftcallback',
    component: () => import('../components/MicrosoftCallback.vue')
  },
  {
    path: '/SaleOrder',
    name: 'saleorder',
    component: () => import('../components/AppSaleOrder.vue')
  },
  {
    path: '/Trans',
    name: 'trans',
    component: () => import('../components/AppTrans.vue')
  },
  {
    path: '/Catalog',
    name: 'catalog',
    component: () => import('../components/AppCatalog.vue')
  },
  {
    path: '/Comon',
    name: 'comon',
    component: () => import('../components/AppComon.vue')
  },
  {
    path: '/Setting',
    name: 'setting',
    component: () => import('../components/AppSetting.vue')
  },  {
    path: '/Party',
    name: 'party',
    component: () => import('../components/AppParty.vue')
  },
  {
    path: '/System',
    name: 'system',
    component: () => import('../components/AppSystem.vue')
  },
  {
    path: '/redirect',
    name: 'redirect',
    component: () => import('../components/AppRedirect.vue')    
  },
]

// const _router = createRouter({
//   history: createWebHistory(),
//   routes
// })

const router = new VueRouter({
  //mode:  'history',
  base: process.env.BASE_URL,
  routes: routes

})

export default router
