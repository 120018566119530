<template>
  <div id="app">
    <v-app id="inspire">
      <div class="bodyIframe">
        <div class="sidebarCortex" :class="{active:isActive}">
          <div class="container">
            <div class="sidebar" :class="{active:isActive}">
              <div class="menu-btn" @click="isActive=!isActive" >
                <i class="ph-bold ph-caret-left"></i>
              </div>
              <div class="head">
                <div class="user-img">
                  <img
                    src="https://bizweb.dktcdn.net/100/415/690/themes/804206/assets/logo.png?1670472192221"
                    alt="Vietmap"
                    
                  />
                </div>
                <div class="user-detail">
                  <p class="Sidetitle">Vietmap Cortex App</p>
                </div>
              </div>
              <div class="nav">
                <div class="menu">
                  <p class="Sidetitle">Danh mục</p>
                  <ul>
                    <li class="active">
                      <router-link
                        class="cortextNav-link"
                        @click="onclickActive"
                        to="/"
                      >
                        <i class="icon ph-bold ph-house-simple"></i>
                        <span class="text">Dashboard </span>
                      </router-link>
                    </li>
                    <li class="">
                      <router-link
                        class="cortextNav-link"
                        @click="onclickActive"
                        to="/SaleOrder"
                      >
                        <i class="icon ph-bold ph-clipboard-text"></i>
                        <span class="text">Bán Hàng</span>
                      </router-link>
                    </li>
                    <li>
                      <router-link class="cortextNav-link" to="/Trans">
                        <i class="icon ph-bold ph-credit-card"></i>
                        <span class="text">Giao Dịch</span>
                      </router-link>
                    </li>
                    <li class="">
                      <router-link class="cortextNav-link" to="/Catalog">
                        <i class="icon ph-bold ph-storefront"></i>
                        <span class="text">Ngành Hàng</span>
                      </router-link>
                    </li>
                    <li>
                      <router-link class="cortextNav-link" to="/Party">
                        <i class="icon ph-bold ph-users"></i>
                        <span class="text">Khách Hàng</span>
                      </router-link>
                    </li>
                    <li>
                      <router-link class="cortextNav-link" to="/Comon">
                        <i class="icon ph-bold ph-list-bullets"></i>
                        <span class="text">Danh Mục</span>
                      </router-link>
                    </li>
                    <li>
                      <router-link class="cortextNav-link" to="/Setting">
                        <i class="icon ph-bold ph-gear"></i>
                        <span class="text">Cài Đặt</span>
                      </router-link>
                    </li>
                    <li>
                      <router-link class="cortextNav-link" to="/System">
                        <i class="icon ph-bold ph-desktop-tower"></i>
                        <span class="text">Hệ Thống</span>
                      </router-link>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="menu menuBottom">
                <p class="menu Sidetitle">Tài khoản</p>
                <ul>
                  <li>
                    <a  @click="logout" class="btn-logout">
                      <v-btn x-small color="#000" plain @click="logout">
                        <i class="icon ph-bold ph-sign-out"></i>                      
                        <span class="text"> {{email.replace('@vietmap.vn', '')|| 'Tài khoản'}}</span>
                      </v-btn>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="iframe">
          <!-- <div class="header-iframe"></div> -->
          <v-main style="margin-right: 12px;">
            <router-view  class="Iframe-Router-view"/>
          </v-main>
          <!-- <div class="bottom-iframea"></div> -->
        </div>
      </div>
    </v-app>
  </div>
</template>

<script>
//  import SidebarComponent from "./components/SidebarComponent.vue";
import json from "../public/config.json";

export default {
  data: () => ({
    appName: "Vietmap Internal App",
    email: "",
    config: json,
    isActive:false
  }),
  updated() {
    let routeName = this.$route.name;
    let appName = localStorage.getItem("AppName");
    this.appName =
      routeName != "home"
        ? appName != undefined
          ? appName
          : "Vietmap Internal App"
        : "Vietmap Internal App";

    if (routeName != "microsoftcallback" && routeName != "redirect") {
      let email = localStorage.getItem("Email");
      if (email == null) {
        location.href =
          //  "https://sso.vietmap.vn/#/login?force=true&appId=process.maker&returnUrl=http%3A%2F%2Fcortex.vietmap.io%2F%23%2Fmicrosoftcallback"; // PROD
         "https://sso.vietmap.vn/#/login?force=true&appId=process.maker&returnUrl=http%3A%2F%2Ftesting-cortex.vietmap.io%2F%23%2Fmicrosoftcallback"; // TESTING
          //"https://sso.vietmap.vn/#/login?force=true&appId=process.maker&returnUrl=http%3A%2F%2Fstaging-cortex.vietmap.io%2F%23%2Fmicrosoftcallback"; // STAGING
          // "https://sso.vietmap.vn/#/login?force=true&appId=process.maker&returnUrl=http%3A%2F%2Flocalhost%3A8080%2F%23%2Fmicrosoftcallback";
         // "https://sso.vietmap.vn/#/login?force=true&appId=process.maker&returnUrl=http%3A%2F%2F192.168.34.143%3A8080%2F%23%2Fmicrosoftcallback";
         //"https://sso.vietmap.vn/#/login?force=true&appId=process.maker&returnUrl=http%3A%2F%2Fdx.vml-platform.web.product1.2.s3-website-ap-southeast-2.amazonaws.com%2F%23%2Fmicrosoftcallback"; // PRODUCTION V1.2
        this.email = email;
      }
    }
  },
  methods: {
    goToAppCategory() {
      location.href = "#/";
    },
    clearCached() {
      console.log("clearCached");
      window.open(
        "https://jet.vietmap.vn/public/pcz1e7am7koqxfh5rilq5thsyix8192j"
      );
    },
    logout() {
      localStorage.removeItem("Email");
      window.localStorage.clear();
      window.sessionStorage.clear();
      //location.href = "https://sso.vietmap.vn/#/login?force=true";
      location.href =
        "https://sso.vietmap.vn/#/login?force=true&appId=process.maker&returnUrl=http%3A%2F%2Ftesting-cortex.vietmap.io%2F%23%2Fmicrosoftcallback";
    },
  },
  onclickActive() {
    const navbarLinks = document.querySelectorAll(".cortextNav-link");
    navbarLinks.forEach((link) => {
      link.addEventListener("click", () => {
        navbarLinks.forEach((item) => item.classList.remove(".active"));
        link.classList.add(".active");
      });
    });
  },


};
</script>
<style>
.v-list-item {
  cursor: pointer !important;
}
.v-application .primary {
  background-color: #3b82f6 !important;
  border-color: #3b82f6 !important;
}
.my-header {
  position: fixed;
  width: 100%;
  z-index: 999 !important;
}
.my-header-item {
  float: left;
  width: 33.33%;
  padding: 11px;
}
.my-trans {
  background: #ffffff;
  width: 60%;
  z-index: -1;
}
.my-left {
  background: #ffffff;
  width: 20%;
}
.my-right {
  text-align: right;
  background: #ffffff;
  font-weight: bold;
  font-size: 10pt;
  width: 20%;
}
.v-btn--fab.v-size--x-small {
  height: 24px !important;
}
.cortextNav {
  text-align: center;
  color: #000;
}
.cortextNav-link:hover {
  color: rgb(52, 25, 218) !important;
  background-color: rgba(225, 221, 251, 0.3);
}
.cortextNav-link {
  margin: 0 5px;
  padding: 8px 10px;
  text-decoration: none;
  color: #5a5959 !important;
  list-style-type: none;
  font-weight: 400;
  font-size: 14px;
  border-radius: 8px;
}


body {
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-y: scroll;
}
body::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

/* test */
.bodyIframe {
  height: 100%;
  width: 100%;
  background-color: #f8fafc;
  display: flex;
  justify-content: space-between;
}
.sidebarCortex {
  width: 360px;
  position: relative;
}
.iframe {
  width: 100%;
}

/* ---------------------------------- sidebar ------------------------------- */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Inter", sans-serif;
}

body{
  background-color: #f8fafc;
}
.container {
  width: 100%;
  min-height: 100vh;
}
.textBottom {
  font-family: "Inter", sans-serif !important;
}
.sidebar {
  position: fixed;
  width: 250px;
  height: 96.8vh;
  min-height: 680px;
  display: flex;
  gap: 20px;
  flex-direction: column;
  background-color: #fff;
  padding: 24px;
  border-radius: 20px;
  transition: all 0.3s;
  padding-bottom: 0;
  border: 0 solid #cdd5df;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
    rgba(0, 0, 0, 0.06) 0px 1px 2px 0px !important;
}
.sidebar .head {
  gap: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #939292a1;
}
.user-detail .Sidetitle {
  text-align: center;
}
.user-detail .Sidetitle,
.menu .Sidetitle {
  font-size: 14px;
  font-weight: 500;
  color: #757575;
  text-transform: uppercase;
  margin-bottom: 10px;
  transition: all 0.3s;
}

.nav {
  flex: 1;
}
.menu ul li {
  position: relative;
  list-style: none;
  margin-bottom: 5px;
}
.menu ul li a {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 14px;
  font-weight: 500;
  color: #757575;
  text-decoration: none;
  padding: 12px 8px;
  border-radius: 8px;
  transition: all 0.3s;
}
.menu ul li > a:hover,
.menu ul li > a.router-link-exact-active {
  color: rgb(52, 25, 218) !important;
  background-color: rgba(225, 221, 251, 0.3);
}
.menu ul li .icon {
  font-size: 20px;
}
.menu ul li .text {
  flex: 1;
}

.menu ul li .arrow {
  font-size: 14px;
  transform: all 0.3s;
}
.menu ul li .arrow.router-link-exact-active {
  transform: rotate(180deg);
}

.menu .sub-menu {
  display: none;
  margin-left: 20px;
  padding-left: 20px;
  padding-top: 5px;
  border-left: 1px solid #f6f6f6;
}
.menu .sub-menu li a {
  padding: 10px 8px;
  font-size: 12px;
}
.menu:not(:last-child) {
  padding-bottom: 10px;
  margin-bottom: 20px;
  /* border-bottom: 2px solid #f6f6f6; */
}
.menu-btn {
  position: absolute;
  right: -14px;
  top: 2.2%;
  width: 28px;
  height: 28px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #757575;
  background-color: #fff;
  border: 0 solid #cdd5df;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
    rgba(0, 0, 0, 0.06) 0px 1px 2px 0px !important;
    z-index: 100;
}

.menu-btn:hover i {
  color: #000;
}
.menu-btn i {
  transition: all 0.3s;
}
.menuBottom {
  gap: 20px;
  padding-top: 20px;
  border-top: 1px solid #939292a1;
  left: -2px;
}
.menuBottom a {
  position: relative;
  left: -10px;
}
.btn-logout{
  bottom: 12px;
}
.header-iframe{
  position: fixed;
  width: 100%;
  height: 48px;
  z-index: 999 !important;
  background-color: #f8fafc;
  display: none;
}

.bottom-iframe{
  position: fixed;
  width: 100%;
  height: 50px;
  bottom:  0px;
  z-index: 999 !important;
  background-color: #f8fafc;
}
.sidebar .user-img img {
  transition: all 0.3s;  
  max-height: 40px;
  width: 100%;
 }

/* -------------------------------------------------------------------------------------------  */
/* #iframe {
  position: relative;
  bottom: 18px;
} */

.sidebarCortex.active{
    width: 150px;

}

.sidebar.active{
    width: 100px;
    z-index: 100
}

.sidebar.active ul{
   padding: 0;
    
}
.sidebar.active .menu-btn i {
    transform: rotate(180deg);
}

.sidebar.active .user-detail {
    display: none;
}

.sidebar.active .user-detail .Sidetitle,
.sidebar.active .menu .Sidetitle {
  transition: all 0.5s;
}

.sidebar.active .menu .title {
    text-align: center;
}

.sidebar.active .menu ul li .arrow {
    display: none;
}

.sidebar.active .menu > ul > li > a {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}
.Iframe-Router-view{
  height: 98.5%;
  transition: all 0.3s; 
 border-radius: 20px;  
 overflow: hidden;
 position: relative;
 top:  16px;
 margin-left: -10px;
}

.sidebar.active .menu > ul > li > a .text {
   position: absolute;
   left: 70px;
   top: 50%;
   transform: translateY(-50%);
   padding: 10px;
   border-radius: 4px;
   color: #fff;
   background-color: #000;
   opacity: 0;
   visibility: hidden;
   transition: all 0.3s;
   z-index: 1000;
   width: 120px;
}

.sidebar.active .menu > ul > li > a .text::after {
    content: "";
    position: absolute;
    left: -5px;
    top: 20%;
    width: 20px;
    height: 20px;
    border-radius: 2px;
    background-color: #000;
    transform: rotate(45deg);
    z-index: -1;
    
 } 
 .sidebar.active .menu > ul > li > a:hover .text {
    left: 50px;
    opacity: 1;
    visibility: visible;
    z-index: 1000;
    width: 120px;
 }
 .sidebar.active .user-img img {
  width: 100%;
  left: -10px;
  max-height: 14px;
  transition: all 0.3s;
  
 }

 .sidebar.active .menu .sub-menu {
    position: absolute;
    top: 0;
    left: 20px;
    width: 200px;
    border-radius: 20px;
    padding: 10px 20px;
    border: 1px solid #f6f6f6;
    background-color: #fff;
 }
 .sidebar.active  .Sidetitle{
    font-size: 12px ;
    position: relative;
    width: 100px;
    left: -8px;
 }
 .sidebar.active  .btn-logout{ 
    left: 0px;
    bottom: 12px;
 }
</style>